import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import TitleHeading from '../../components/TitleHeading';
import WaterProcess from '../../components/WaterProcess';
import Img from "gatsby-image"

const OurWater = (props) => {
  const waterImage = props.data.file.childImageSharp.fluid
  return (
    <div id="section-our-water" className="section-our-water w-100 d-flex align-items-center">
      <div className="container d-flex flex-column align-items-center my-4">
        <TitleHeading heading="OUR WATER" />
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex flex-column">
            <WaterProcess className="section-our-water-pp mt-5" title="Step 1 - PP Filter" 
              description="removes dirty small particles and sedimentation such as sand, rust, and particle deposition"/>
            <WaterProcess className="section-our-water-c1 mt-14" title="Step 2 - C1 Filter"
              description="stop chlorine and VOCs which is harmful to the body"/>
          </div>
          <Img className="section-our-water-img align-self-center flex-grow-1" fluid={waterImage}/>
          <div className="d-flex flex-column">
            <WaterProcess className="section-our-water-ro mt-4" title="Step 3 - RO Filter" 
              description="eliminate heavy metals, microbiology, bacteria’s, and virus with 0.0005 microns membrane"/>
            <WaterProcess className="section-our-water-c2 mt-14" title="Step 4 - C2 Filter"
              description="removes odors and stabilizes taste to get the best fresh water"/>
          </div>
        </div>
      </div>
    </div>
  );
}


export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "our_water.png" }) {
          childImageSharp {
            fluid (quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
  `}
    render={data => <OurWater data={data} />}
  />
);