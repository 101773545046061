import React from 'react';

const WaterProcess = props => {
  return (
    <div className={`water-process text-center ${props.className}`}>
        <h3><small>{props.title}</small></h3>
        <p><small>{props.description}</small></p>
    </div>    
  );
}

export default WaterProcess;